<template>
  <v-card class="upload-card fill-height" flat>
    <v-card-actions>
      <v-spacer />
      <StoreCompany v-model="updatedCompany" @companiesUpdated="getCompanies" />
    </v-card-actions>

    <v-data-table :headers="headers" :items="companies" :loading="loading">
      <template v-slot:items="props">
        <CompanyItem
          :key="props.item.id"
          :company="props.item"
          @companiesUpdated="getCompanies"
          @editCompany="edit(props.item.id)"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import StoreCompany from '@/pages/admin/StoreCompany'
import CompanyItem from '@/pages/admin/items/CompanyItem'
import { mapGetters } from 'vuex'

export default {
  name: 'Companies',
  components: { CompanyItem, StoreCompany },
  data() {
    return {
      currentUser: null,
      dialog: false,
      loading: false,
      headers: [
        { text: '', sortable: false },
        { text: 'Firmenname', value: 'name' },
        { text: 'Adresse', value: 'address' },
        { text: '', value: 'actions', align: 'right' },
      ],
      updatedCompany: null,
      companySettingsId: null,
    }
  },
  computed: {
    ...mapGetters(['companies']),
  },
  methods: {
    async getCompanies() {
      try {
        this.loading = true
        await this.$store.dispatch('fetchCompanies')
      } catch (e) {
        this.$root.setSnackbar('error', e.message)
      } finally {
        this.loading = false
      }
    },
    edit(id) {
      this.updatedCompany = id
    },
  },
  mounted() {
    this.getCompanies()
  },
}
</script>

<style scoped></style>
