<template>
  <div>
    <v-btn @click="dialog = true" class="mr-4" color="primary" dark
      >Firma erstellen
    </v-btn>

    <v-dialog max-width="500" persistent v-model="dialog">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="company.name_full"
            :disabled="loading"
            label="* Name"
          />
          <v-text-field
            v-model="company.name"
            :disabled="loading"
            label="* Label/Kurzname"
          />

          <v-subheader class="pl-0">Adresse</v-subheader>
          <v-text-field
            v-model="company.address_street"
            :disabled="loading"
            label="* Straße"
          />
          <v-layout>
            <v-flex class="mr-2" md3>
              <v-text-field
                v-model="company.address_zip"
                :disabled="loading"
                label="* PLZ"
              />
            </v-flex>
            <v-flex class="ml-2">
              <v-text-field
                v-model="company.address_city"
                :disabled="loading"
                label="* Stadt"
              />
            </v-flex>
          </v-layout>

          <v-subheader class="pl-0">Bank</v-subheader>
          <v-text-field
            v-model="company.bank_name"
            :disabled="loading"
            label="* Bank Name"
          />
          <v-text-field
            v-model="company.bank_iban"
            :disabled="loading"
            :rules="[validations.iban]"
            label="* Bank IBAN"
            validate-on-blur
          />
          <v-text-field
            v-model="company.bank_bic"
            :disabled="loading"
            :rules="[validations.bic]"
            label="* Bank BIC"
            validate-on-blur
          />

          <v-subheader class="pl-0">Sonstiges</v-subheader>
          <v-text-field
            v-model="company.gtc"
            :disabled="loading"
            label="* AMANA GTC"
          />

          <v-text-field
            v-model="company.hq_ags"
            :disabled="loading"
            label="* AGS Hauptsitz"
          />
          <v-text-field
            v-model="company.vat_id"
            :disabled="loading"
            label="* Steuernummer"
          />
          <v-text-field
            v-model="company.mandator"
            :disabled="loading"
            label="* Mandant"
          />

          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="company.main_color"
                :disabled="loading"
                hide-details
                label="* Farbe"
              >
                <template v-slot:prepend>
                  <v-icon :color="company.main_color"
                    >fiber_manual_record</v-icon
                  >
                </template>
                <template v-slot:append-outer>
                  <v-icon v-on="on">edit</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-list dense>
              <v-list-tile
                v-for="(item, index) in colors"
                :key="index"
                avatar
                @click="company.main_color = item"
              >
                <v-list-tile-avatar>
                  <v-avatar :color="item" size="24"></v-avatar>
                </v-list-tile-avatar>
                <v-list-tile-title>{{ item }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :loading="loading" :disabled="loading" @click="close"
            >Abbrechen
          </v-btn>
          <v-btn
            :disabled="loading || !isValid"
            :loading="loading"
            color="success"
            @click="storeCompany"
            >Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import companiesApi from '@/api/companiesApi'
import { isValidBic, isValidIBANNumber } from '@/utils/paymentUtils'
import predefinedColors from '@/utils/predefinedColors'

function resetCompanyData() {
  return {
    id: null,
    name: '',
    name_full: '',
    address_street: '',
    address_city: '',
    address_zip: '',
    gtc: '',
    bank_name: '',
    bank_iban: '',
    bank_bic: '',
    mandator: '',
    vat_id: '',
  }
}

export default {
  name: 'StoreCompany',
  props: {
    corporations: Array,
    value: Number,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      company: resetCompanyData(),
      updated: null,
      validations: {
        iban: (value) => isValidIBANNumber(value) || 'Keine gültige IBAN',
        bic: (value) => isValidBic(value) || 'Keine gültige BIC',
      },
    }
  },
  methods: {
    close() {
      this.resetCompany()
      this.dialog = false
      this.$emit('input', null)
    },
    resetCompany() {
      this.company = resetCompanyData()
    },
    async storeCompany() {
      try {
        this.loading = true
        let result
        if (this.company.id) {
          result = await companiesApi.update(this.company.id, this.company)
          const { success } = result
          this.$root.setSnackbar('success', success.message)
        } else {
          result = await companiesApi.store(this.company)
          const { name } = result
          this.$root.setSnackbar('success', `Firma ${name} wurde angelegt`)
        }
        this.$emit('companiesUpdated')
        this.close()
      } catch (e) {
        this.$root.setSnackbar('error', e.message)
      } finally {
        this.loading = false
      }
    },
    async getUpdatedCompany(id) {
      try {
        this.loading = true
        this.company = await companiesApi.getCompany(id)
      } catch (e) {
        this.$root.setSnackbar('error', e.message)
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
    isValid() {
      return [
        'name',
        'name_full',
        'address_street',
        'address_city',
        'address_zip',
        'gtc',
        'bank_name',
        'bank_bic',
        'bank_iban',
        'vat_id',
      ].every((el) => this.company[el])
    },
    title() {
      return this.company.id ? 'Firma bearbeiten' : 'Firma erstellen'
    },
    colors() {
      return predefinedColors
    },
  },
  watch: {
    value: {
      handler(id) {
        if (id) {
          this.dialog = true
          this.getUpdatedCompany(id)
        } else {
          this.resetCompany()
        }
      },
    },
  },
}
</script>

<style scoped></style>
