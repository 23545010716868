export default [
  '#143c8c',
  '#ffcd2f',
  '#43A047',
  '#512da8',
  '#F44336',
  '#009688',
  '#C0CA33',
  '#FB8C00',
  '#6D4C41',
  '#E91E63',
  '#03A9F4',
  '#607D8B',
]
