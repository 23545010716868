<template>
  <tr v-if="!isDeleted">
    <td>
      <v-icon :color="company.main_color">fiber_manual_record</v-icon>
    </td>
    <td>
      {{ company.name_full }}
    </td>
    <td>
      {{ company.address_street }}, {{ company.address_zip }}
      {{ company.address_city }}
    </td>
    <td class="text-xs-right">
      <v-btn
        :loading="loading"
        :disabled="loading"
        @click="$emit('editCompany')"
        icon
      >
        <v-icon>edit</v-icon>
      </v-btn>
      <v-btn
        :loading="loading"
        :disabled="loading"
        icon
        @click="remove(company.id)"
      >
        <v-icon>delete</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import companiesApi from '@/api/companiesApi'

export default {
  name: 'CompanyItem',
  props: {
    company: Object,
    corpName: String,
  },
  data() {
    return {
      loading: false,
      isDeleted: false,
      biConfig: null,
      showConfig: false,
    }
  },
  methods: {
    async remove(id) {
      try {
        this.loading = true
        await companiesApi.remove(id)
        this.$emit('companiesUpdated')
        this.isDeleted = true
      } catch (e) {
        const errObj = e.errorObject
        if (errObj) {
          const [msg] = e.errorObject.message
          this.$root.setSnackbar('error', msg)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
